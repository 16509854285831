/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {CheckAccess, IsRootUserOrAdmin} from '../../../../../app/services/Functional'

const SidebarMenuMain = () => {
    const intl = useIntl()

    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />
            {/*<SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />*/}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Booking</span>
                </div>
            </div>
            {CheckAccess('quotations.index') && <SidebarMenuItem
                to='/quotations'
                icon='/media/icons/duotune/general/gen025.svg'
                title='Quotations'
                fontIcon='bi-layers'
            />}
            {CheckAccess('bookings.index') && <SidebarMenuItem
                to='/bookings'
                icon='/media/icons/duotune/general/gen022.svg'
                title='Bookings'
                fontIcon='bi-layers'
            />}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
                </div>
            </div>

            {CheckAccess('agents.index') && <SidebarMenuItem
                to='/agents'
                icon='/media/icons/duotune/general/gen022.svg'
                title='Agents'
                fontIcon='bi-layers'
            />}
            {CheckAccess('hotels.index') && <SidebarMenuItem
                to='/hotels'
                icon='/media/icons/duotune/general/gen051.svg'
                title='Hotels'
                fontIcon='bi-layers'
            />}

            {CheckAccess('sight_seeings.index') && <SidebarMenuItem
                to='/sight_seeings'
                icon='/media/icons/duotune/general/gen025.svg'
                title='Sight Seeings'
                fontIcon='bi-layers'
            />}
            {CheckAccess('transfers.index') && <SidebarMenuItem
                to='/transfers'
                icon='/media/icons/duotune/general/gen016.svg'
                title='Transfers'
                fontIcon='bi-layers'
            />}
            {CheckAccess('packages.index') && <SidebarMenuItem
                to='/packages'
                icon='/media/icons/duotune/general/gen019.svg'
                title='Packages'
                fontIcon='bi-layers'
            />}
            {CheckAccess('leaves.index') && <SidebarMenuItem
                to='/leaves'
                icon='/media/icons/duotune/general/gen019.svg'
                title='Leaves'
                fontIcon='bi-layers'
            />}

            <SidebarMenuItemWithSub
                to='/attendances'
                title='Attendances'
                icon='/media/icons/duotune/general/gen013.svg'
                fontIcon='bi-layers'
            >
                {CheckAccess('attendances.index') && <>
                    <SidebarMenuItem
                        to='/attendances/list' title='List' hasBullet={true}
                    />
                    <SidebarMenuItem
                        to='/attendances/calender' title='Calender' hasBullet={true}
                    />
                </>}
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
                to='/masters'
                title='Masters'
                icon='/media/icons/duotune/general/gen025.svg'
                fontIcon='bi-layers'
            >
                {CheckAccess('banners.index') && <SidebarMenuItem
                    to='/masters/banners' title='Banners' hasBullet={true}
                />}
                {CheckAccess('document_types.index') && <SidebarMenuItem
                    to='/masters/document_types' title='Document Types' hasBullet={true}
                />}
                {CheckAccess('hotel_amenities.index') && <SidebarMenuItem
                    to='/masters/hotel_amenities' title='Hotel Amenities' hasBullet={true}
                />}
                {CheckAccess('room_amenities.index') && <SidebarMenuItem
                    to='/masters/room_amenities' title='Room Amenities' hasBullet={true}
                />}
                {CheckAccess('piers.index') && <SidebarMenuItem
                    to='/masters/piers' title='Piers' hasBullet={true}
                />}
                {CheckAccess('airports.index') && <SidebarMenuItem
                    to='/masters/airports' title='Airports' hasBullet={true}
                />}
                {CheckAccess('master_hotels.index') && <SidebarMenuItem
                    to='/masters/master_hotels' title='Master Hotels' hasBullet={true}
                />}
                {CheckAccess('suppliers.index') && <SidebarMenuItem
                    to='/masters/suppliers' title='Suppliers' hasBullet={true}
                />}
                {CheckAccess('supplier_types.index') && <SidebarMenuItem
                    to='/masters/supplier_types' title='Supplier Types' hasBullet={true}
                />}{CheckAccess('leave_types.index') && <SidebarMenuItem
                to='/masters/leave_types' title='Leave Types' hasBullet={true}
                />}
                {CheckAccess('holidays.index') && <SidebarMenuItem
                    to='/masters/holidays' title='Holidays' hasBullet={true}
                />}
                {CheckAccess('countries.index') && <SidebarMenuItem
                    to='/masters/countries' title='Countries' hasBullet={true}
                />}
                {CheckAccess('cities.index') && <SidebarMenuItem
                    to='/masters/cities' title='Cities' hasBullet={true}
                />}
            </SidebarMenuItemWithSub>

            {IsRootUserOrAdmin() && <>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administrative Links</span>
                    </div>
                </div>
                {CheckAccess('users.index') && <SidebarMenuItem
                    to='/users'
                    icon='/media/icons/duotune/communication/com006.svg'
                    title='User management'
                    fontIcon='bi-layers'
                />}
                {CheckAccess('groups.index') && <SidebarMenuItem
                    to='/groups'
                    icon='/media/icons/duotune/general/gen051.svg'
                    title='User Groups'
                    fontIcon='bi-layers'
                />}
                {CheckAccess('configurations.index') && <SidebarMenuItem
                    to='/configurations'
                    icon='/media/icons/duotune/general/gen051.svg'
                    title='Configurations'
                    fontIcon='bi-layers'
                />}
            </>}

            {/*<SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>*/}

            {/*<SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>*/}
            {/*<div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>*/}
        </>
    )
}

export
{
    SidebarMenuMain,
}
