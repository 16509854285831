import React, {FC, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Col, Modal, ModalBody, ModalHeader, ModalTitle, Row} from 'react-bootstrap'
import {ErrorMessage, Form, Formik} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import {assignSupplierToCustomService} from '../../../../setup/redux/bookings/actions'
import {getSuppliers} from "../../../../setup/redux/suppliers/actions";
import {RootState} from "../../../../setup";

export interface assignSupplier {
    show: boolean,
    hide: any,
    customService: any,
    refetch: any
}

export const ManageCustomServiceSupplier: FC<assignSupplier> = ({show, hide, customService, refetch}) => {
    const dispatch = useDispatch()
    const assignSupplier = {
        custom_service_id: customService.id,
        supplier_id: '',
    }
    const assignSupplierSchema = Yup.object().shape({
        supplier_id: Yup.string().required('Supplier is required'),
    })
    useEffect(() => {
        dispatch(getSuppliers('custom_service', customService.id))
    }, []);

    const {supplierLists}: any = useSelector<RootState>(
        (state) => ({
            supplierLists: state.suppliers.supplierLists
        }),
        shallowEqual,
    )
    return (
        <Modal show={show} centered={true} onHide={hide}>
            <ModalHeader closeButton>
                <ModalTitle>
                    Assign Supplier
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={assignSupplier}
                    validationSchema={assignSupplierSchema}
                    onSubmit={async (values) => {
                        await dispatch(assignSupplierToCustomService(values))
                        refetch()
                        hide()
                    }}
                >
                    {({values, handleChange, handleBlur, handleSubmit}) => (
                        <React.Fragment>
                            <Form className='form'>
                                <Row className={'px-2'}>
                                    <Col md={12}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>
                                                Supplier <span className='text-danger'>*</span>
                                            </label>
                                            <Select
                                                options={supplierLists}
                                                value={supplierLists.find((supplier: {
                                                    value: string
                                                }) => supplier.value === values.supplier_id)}
                                                name='supplier_id'
                                                className='react-select-container'
                                                classNamePrefix='react-select'
                                                placeholder='Select a supplier'
                                                onChange={option => {
                                                    let event = {
                                                        target: {
                                                            name: 'supplier_id',
                                                            value: option?.value,
                                                        },
                                                    }
                                                    handleChange(event)
                                                }}
                                                onBlur={() => {
                                                    handleBlur({target: {name: 'supplier_id'}})
                                                }}
                                            />
                                            <ErrorMessage
                                                name={`supplier_id`}
                                                component='div' className='text-danger mt-2'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={'float-end'}>
                                    <div className='col-md-12 '>
                                        <div className='form-group'>
                                            <button
                                                type='button'
                                                name='submit'
                                                className='btn btn-primary me-2'
                                                onClick={() => handleSubmit()}
                                            >
                                                Assign Now
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}