import React, {FC} from 'react'
import moment from 'moment'
import {capitalizeFirstLetter, getRoomInclusion} from '../../services/Functional'

export const GetTotalServices = ({booking}: any) => {
    return booking.booking_hotels.filter((hotel: {
            booking_status: string
        }) => hotel.booking_status !== 'cancelled').length
        + booking.booking_sight_seeings.filter((sight_seeing: {
            booking_status: string
        }) => sight_seeing.booking_status !== 'cancelled').length
        + booking.booking_transfers.filter((transfer: {
            booking_status: string
        }) => transfer.booking_status !== 'cancelled').length
        + booking.booking_packages.filter((packageData: {
            booking_status: string
        }) => packageData.booking_status !== 'cancelled').length
        + booking.booking_custom_services.filter((custom: {
            booking_status: string
        }) => custom.booking_status !== 'cancelled').length
        + booking.booking_custom_hotel_services.filter((custom: {
            booking_status: string
        }) => custom.booking_status !== 'cancelled').length
}

export function getTotalPendingServices(booking: any) {
    return booking.booking_hotels.filter((hotel: {
            booking_status: string
        }) => hotel.booking_status === 'processed').length
        + booking.booking_sight_seeings.filter((sight_seeing: {
            booking_status: string
        }) => sight_seeing.booking_status === 'processed').length
        + booking.booking_transfers.filter((transfer: {
            booking_status: string
        }) => transfer.booking_status === 'processed').length
        + booking.booking_packages.filter((packageData: {
            booking_status: string
        }) => packageData.booking_status === 'processed').length
        + booking.booking_custom_services.filter((custom: {
            booking_status: string
        }) => custom.booking_status === 'processed').length
        + booking.booking_custom_hotel_services.filter((custom: {
            booking_status: string
        }) => custom.booking_status === 'processed').length
}

export function isAllServicesCancelled(booking: any) {
    const totalService = GetTotalServices({booking});
    return totalService > 0
}

export const GetTotalVoucherServices = ({booking}: any) => {
    const hotels = booking.booking_hotels.filter((hotel: {
        booking_status: string
    }) => hotel.booking_status === 'voucher')
    const sightSeeings = booking.booking_sight_seeings.filter((sightSeeing: {
        booking_status: string
    }) => sightSeeing.booking_status === 'voucher')
    const transfers = booking.booking_transfers.filter((transfer: {
        booking_status: string
    }) => transfer.booking_status === 'voucher')
    const packages = booking.booking_packages.filter((packageData: {
        booking_status: string
    }) => packageData.booking_status === 'voucher')
    const customServiceData = booking.booking_custom_services.filter((customService: {
        booking_status: string
    }) => customService.booking_status === 'voucher')
    const customHotelServiceData = booking.booking_custom_hotel_services.filter((customService: {
        booking_status: string
    }) => customService.booking_status === 'voucher')
    return (
        <>{hotels.length + sightSeeings.length + transfers.length + packages.length + customServiceData.length + customHotelServiceData.length} Voucher</>
    )
}
export const GetTotalServiceDetails = ({booking}: any) => {
    let services = ''
    if (booking.booking_hotels.length > 0) {
        const hotels = booking.booking_hotels.filter((hotel: {
            booking_status: string
        }) => hotel.booking_status !== 'cancelled').length
        services += hotels + ' Hotel' + (hotels > 1 ? 's' : '') + ', '
    }
    if (booking.booking_sight_seeings.length > 0) {
        const sightSeeings = booking.booking_sight_seeings.filter((sight_seeing: {
            booking_status: string
        }) => sight_seeing.booking_status !== 'cancelled').length
        services += sightSeeings + ' Sight Seeing' + (sightSeeings > 1 ? 's' : '') + ', '
    }
    if (booking.booking_transfers.length > 0) {
        const transfers = booking.booking_transfers.filter((hotel: {
            transfer: string
        }) => hotel.transfer !== 'cancelled').length
        services += transfers + ' Transfer' + (transfers > 1 ? 's' : '') + ', '
    }
    if (booking.booking_packages.length > 0) {
        const packageData = booking.booking_packages.filter((packageInfo: {
            booking_status: string
        }) => packageInfo.booking_status !== 'cancelled').length
        services += packageData + ' Package' + (packageData > 1 ? 's' : '') + ', '
    }
    if (booking.booking_custom_services.length > 0) {
        const servicesData = booking.booking_custom_services.filter((custom: {
            booking_status: string
        }) => custom.booking_status !== 'cancelled').length
        services += servicesData + ' Custom Service' + (servicesData > 1 ? 's' : '') + ', '
    }
    if (booking.booking_custom_hotel_services.length > 0) {
        const servicesData = booking.booking_custom_hotel_services.filter((custom: {
            booking_status: string
        }) => custom.booking_status !== 'cancelled').length
        services += servicesData + ' Custom Hotel Service' + (servicesData > 1 ? 's' : '') + ', '
    }
    return (
        <>{services.slice(0, -2)}</>)
}

export const GetRoomCategoryAndPrice = ({rooms}: any) => {
    let roomResponse: any = {}
    let price = 0
    let roomString = ''
    for (const room of rooms) {
        price = price + room.sell_price
        if (roomResponse.hasOwnProperty(room.room.name)) {
            roomResponse[room.room.name] += 1
        } else {
            roomResponse[room.room.name] = 1
        }
    }
    for (const [key, value] of Object.entries(roomResponse)) {
        roomString = roomString + value + ' ' + key + ', '
    }
    return (
        <>
            <td>{roomString.slice(0, -2)}</td>
            <td>USD {price.toFixed(2)}</td>
        </>
    )
}

export const GetCustomRoomCategoryAndPrice = ({hotel}: any) => {
    let roomResponse: any = {}
    let price = 0
    let roomString = ''
    for (const room of hotel.rooms) {
        if (roomResponse.hasOwnProperty(room.type + ' - ' + room.inclusion)) {
            roomResponse[room.type + ' - ' + room.inclusion] += 1
        } else {
            roomResponse[room.type + ' - ' + room.inclusion] = 1
        }
    }

    for (const [key, value] of Object.entries(roomResponse)) {
        roomString = roomString + value + ' ' + key + ', '
    }
    return (
        <>
            <td>{roomString.slice(0, -2)}</td>
            <td>USD {hotel.sell_price.toFixed(2)}</td>
        </>
    )
}

export const GetRoomCategory = ({rooms}: any) => {
    let roomResponse: any = {}
    let roomString = ''
    if (typeof rooms === 'object') {
        for (const room of rooms) {
            if (roomResponse.hasOwnProperty(room.room.name)) {
                roomResponse[room.room.name] += 1
            } else {
                roomResponse[room.room.name] = 1
            }
        }
        for (const [key, value] of Object.entries(roomResponse)) {
            roomString = roomString + value + ' ' + key + ', '
        }
    }
    return (
        <>{roomString.slice(0, -2)}</>
    )
}
export const GetCustomRoomCategory = ({hotel}: any) => {
    let roomResponse: any = {}
    let roomString = ''
    if (typeof hotel.rooms === 'object') {
        for (const room of hotel.rooms) {
            if (roomResponse.hasOwnProperty(room.type)) {
                roomResponse[room.type] += 1
            } else {
                roomResponse[room.type] = 1
            }
        }
        for (const [key, value] of Object.entries(roomResponse)) {
            roomString = roomString + value + ' ' + key + ', '
        }
    }
    return (
        <>{roomString.slice(0, -2)}</>
    )
}

export function GenerateRoomDetailTable(rooms: any) {
    let roomResponse: any = {}
    let categoryResponse: any = {}
    let allotmentStatus: any = {}
    let price = 0
    let response = []
    for (const room of rooms) {
        price = price + room.sell_price
        if (roomResponse.hasOwnProperty(room.room.name)) {
            roomResponse[room.room.name] += 1
        } else {
            roomResponse[room.room.name] = 1
            categoryResponse[room.room.name] = room.room.markup.category
            allotmentStatus[room.room.name] = room.sale_from
        }
    }
    for (const [key, value] of Object.entries(roomResponse)) {
        response.push(<tr>
            <td>{value} * {key}</td>
            <td className='text-capitalize'>{allotmentStatus[key]}</td>
            <td>{getRoomInclusion(categoryResponse[key])}</td>
        </tr>)
    }
    return response
}

export function GenerateCustomRoomDetailTable(hotel: any) {
    let roomResponse: any = {}
    let categoryResponse: any = {}
    let response = []
    for (const room of hotel.rooms) {
        if (roomResponse.hasOwnProperty(room.type)) {
            roomResponse[room.type] += 1
        } else {
            roomResponse[room.type] = 1
            categoryResponse[room.type] = room.inclusion
        }
    }
    for (const [key, value] of Object.entries(roomResponse)) {
        response.push(<tr>
            <td>{value} * {key}</td>
            <td>{categoryResponse[key]}</td>
        </tr>)
    }
    return response
}

export function GenerateRoomDetailTableForProcessBooking(rooms: any) {
    let roomResponse: any = {}
    let categoryResponse: any = {}
    let price = 0
    let response = []
    for (const room of rooms) {
        price = price + room.sell_price
        if (roomResponse.hasOwnProperty(room.room.name)) {
            roomResponse[room.room.name] += 1
        } else {
            roomResponse[room.room.name] = 1
            categoryResponse[room.room.name] = room.room.markup.category
        }
    }
    response.push(<tr>
        <th>Room Type</th>
        <th>Plan</th>
    </tr>);
    for (const [key, value] of Object.entries(roomResponse)) {
        response.push(<tr>
            <td>{value} * {key}</td>
            <td>{getRoomInclusion(categoryResponse[key])}</td>
        </tr>)
    }
    return response
}

export function GenerateCustomRoomDetailTableForProcessBooking(hotel: any) {
    let roomResponse: any = {}
    let categoryResponse: any = {}
    let response = []
    for (const room of hotel.rooms) {
        if (roomResponse.hasOwnProperty(room.type)) {
            roomResponse[room.type] += 1
        } else {
            roomResponse[room.type] = 1
            categoryResponse[room.type] = room.inclusion
        }
    }
    response.push(<tr>
        <th>Room Type</th>
        <th>Plan</th>
    </tr>);
    for (const [key, value] of Object.entries(roomResponse)) {
        response.push(<tr>
            <td>{value} * {key}</td>
            <td>{categoryResponse[key]}</td>
        </tr>)
    }
    return response
}

export const GetTotalRoomAdults: FC<{ rooms: any }> = ({rooms}) => {
    let adults = 0
    for (const room of rooms) {
        adults = adults + room.adults.length
    }
    return <>{adults}</>
}
export const GetTotalRoomChildren: FC<{ rooms: any }> = ({rooms}) => {
    let children = 0
    for (const room of rooms) {
        children = children + room.children.filter((child: { age: number; }) => child.age > 2).length
    }
    return <>{children}</>
}
export const GetTotalRoomInfant: FC<{ rooms: any }> = ({rooms}) => {
    let children = 0
    for (const room of rooms) {
        children = children + room.children.filter((child: { age: number; }) => child.age <= 2).length
    }
    return <>{children}</>
}

export const GetTotalRoomChildrenDataForProcessBooking: FC<{ rooms: any }> = ({rooms}) => {
    let children = []
    for (const room of rooms) {
        for (const childElement of room.children) {
            children.push(childElement.age > 2
                ? `01 ${childElement.age} Yrs ${childElement.with_bed ? 'CWB' : 'CNB'}`
                : '01 Inft')
        }
    }
    return <>{children.join(', ')}</>
}

export const GetPNRNumber = ({booking}: any) => {
    return <span>{booking.destination_country + booking.pnr_no + String(booking.id).padStart(4, '0')}</span>
}

export function getPNRNumberFunction(booking: any) {
    return booking.destination_country + booking.pnr_no + String(booking.id).padStart(4, '0')
}

export const ManageInvoiceTotal = ({services, type}: any) =>{
    let total = 0
    if(type ==='adult') {
        for (const service of services) {
            total = total + parseFloat(service.adult_price)
        }
    } else {
        for (const service of services) {
            if (service.booking_service === 'tour' && service.child_count === 0 && service.children.length) {
                total = total + parseFloat(service.adult_price)
            } else {
                total = total + parseFloat(service.child_price)
            }
        }
    }
    return <>{total.toFixed(2)}</>

}
export const GetBookingStatusLabel = (props: { status: string }) => {
    switch (props.status) {
        case 'confirmed':
            return <span className="text-primary"><strong>Confirmed</strong></span>
        case 'voucher':
            return <span className="text-success"><strong>Voucher</strong></span>
        case 'cancelled':
            return <span className="text-danger"><strong>Cancelled</strong></span>
        default:
            return <span><strong>Pending</strong></span>
    }
}

export const GetPromotionCode = ({rooms}: any) => {
    const bookingCode = rooms.map((room: { booking_code: any }) => room.booking_code)
    const uniqueBookingCode = new Set(bookingCode)
    return <>{Array.from(uniqueBookingCode).join(', ')}</>
}
export const GetQuotationNumber = ({booking}: any) => {
    return <span>{'Q' + booking.pnr_no + String(booking.id).padStart(4, '0')}</span>
}

export const GetBookingStatus: FC<{ booking: any }> = ({booking}) => {
    let totalServices = 0
    const status: any = {
        processed: 0,
        confirmed: 0,
        cancelled: 0,
        voucher: 0,
    }
    if (booking?.booking_hotels && booking?.booking_hotels.length > 0) {
        for (const hotel of booking?.booking_hotels) {
            if (hotel.booking_status) {
                status[hotel.booking_status] = status[hotel.booking_status] + 1
                totalServices++
            }
        }
    }
    if (booking?.booking_sight_seeings && booking?.booking_sight_seeings.length > 0) {
        for (const sight_seeing of booking?.booking_sight_seeings) {
            if (sight_seeing.booking_status) {
                status[sight_seeing.booking_status] = status[sight_seeing.booking_status] + 1
                totalServices++
            }
        }
    }
    if (booking?.booking_transfers && booking?.booking_transfers.length > 0) {
        for (const transfer of booking?.booking_transfers) {
            if (transfer.booking_status) {
                status[transfer.booking_status] = status[transfer.booking_status] + 1
                totalServices++
            }
        }
    }
    if (booking?.booking_packages && booking?.booking_packages.length > 0) {
        for (const packageData of booking?.booking_packages) {
            if (packageData.booking_status) {
                status[packageData.booking_status] = status[packageData.booking_status] + 1
                totalServices++
            }
        }
    }

    if (booking?.booking_custom_services && booking?.booking_custom_services.length > 0) {
        for (const customService of booking?.booking_custom_services) {
            if (customService.booking_status) {
                status[customService.booking_status] = status[customService.booking_status] + 1
                totalServices++
            }
        }
    }

    if (booking?.booking_custom_hotel_services && booking?.booking_custom_hotel_services.length > 0) {
        for (const customHotelService of booking?.booking_custom_hotel_services) {
            if (customHotelService.booking_status) {
                status[customHotelService.booking_status] = status[customHotelService.booking_status] + 1
                totalServices++
            }
        }
    }

    const maxStatusKey = Object.keys(status).reduce((a, b) => status[a] >= status[b] ? a : b)
    const maxStatusValue = status[maxStatusKey]
    if (maxStatusValue === totalServices) {
        return <>{capitalizeFirstLetter(maxStatusKey)}</>
    } else {
        return <>{capitalizeFirstLetter('partial ' + maxStatusKey ?? 'processed')}</>
    }
}

export const GetHotelPaymentStatus: FC<{ hotel: any }> = ({hotel}) => {
    let totalPrice = 0
    let totalReceivedAmount = 0
    if (hotel.booking_status === 'cancelled') {
        return <>Refunded</>
    }
    for (const room of hotel.rooms) {
        totalPrice += room.sell_price
        totalReceivedAmount += room.received_price_usd
    }
    if (totalReceivedAmount >= totalPrice) {
        return <>Paid</>
    } else if (totalReceivedAmount > 0) {
        return <>Partial</>
    } else {
        return <>Due</>
    }
}
export const GetSightSeeingPaymentStatus: FC<{ sightSeeing: any }> = ({sightSeeing}) => {
    if (sightSeeing.booking_status === 'cancelled') {
        return <>Refunded</>
    }
    if (sightSeeing.received_price_usd >= sightSeeing.sell_price) {
        return <>Paid</>
    } else if (sightSeeing.received_price_usd > 0) {
        return <>Partial</>
    } else {
        return <>Due</>
    }
}
export const GetHotelPaymentDate: FC<{ rooms: any }> = ({rooms}) => {
    let paymentDates: string[] = []
    for (const room of rooms) {
        if (room.payment_date) {
            paymentDates.push(room.payment_date)
        }
    }
    if (paymentDates && paymentDates.length) {
        const dateResponse = paymentDates.map(dateString => moment(dateString))
        return <>{moment.max(dateResponse).format('ddd, D MMMM, YYYY')}</>
    } else {
        return <>-</>
    }
}

export const GetHotelSaleAmount: FC<{ rooms: any }> = ({rooms}) => {
    const total = rooms.reduce(
        (total: number, item: any) => total + parseFloat(item?.sell_price),
        0,
    )
    return <>USD {total}</>
}
export const GetHotelRefundAmount: FC<{ hotel: any }> = ({hotel}) => {
    let refundAmount = 0
    const total = hotel.rooms.reduce(
        (total: number, item: any) => total + parseFloat(item?.sell_price),
        0,
    )
    if (hotel.voucher_no) {
        return <>USD {(total - hotel.cancellation_amount).toFixed(2)}</>
    }
    return <>-</>
}
export const GetHotelPurchaseAmount: FC<{ rooms: any }> = ({rooms}) => {
    const total = rooms.reduce((total: number, item: any) => total + parseFloat(item?.purchase_price_usd), 0)
    return <>USD {total.toFixed(2)}</>
}

export const GetHotelPurchaseAmountOriginal: FC<{ rooms: any }> = ({rooms}) => {
    const total = rooms.reduce((total: number, item: any) => total + parseFloat(item?.purchase_price_usd), 0)
    if (total > 0) {
        return <>{(total / rooms[0].conversion_rate).toFixed(2)}</>
    }
    return <>0</>
}

export const LongDateFormat = ({date}: { date: string }) => {
    if(date) {
        return <>{moment(date).format('ddd, D MMMM, YYYY')}</>
    } else {
        return <>-</>
    }
}
export const LongTimeFormat = ({time}: { time: string }) => {
    if(time) {
        return <>{moment(time, '"HH:mm:ss"').format('HH:mm A')}</>
    } else {
        return <>-</>
    }
}

export function getDaysDifference(date1: string, date2: string) {
    const mDate1 = moment(date1);
    const mDate2 = moment(date2);
    return mDate1.diff(mDate2, 'days');

}

export const PickupPoints = ({flightDetails}: any) => {
    if (flightDetails.length) {
        let pickupPoints = ''
        for (const flightDetail of flightDetails) {
            if (flightDetail.pickup_points) {
                pickupPoints += `<li>${flightDetail.pickup_points}</li>`
            }
        }
        return <div className='row px-2 mt-4'>
            <div className="col-sm-12">
                <strong>Pickup Points:</strong><br/>
                <ul dangerouslySetInnerHTML={{__html: pickupPoints}}/>
            </div>
        </div>

    }
    return <></>
}
export const downloadVoucher = () => {
    const printContents = document.getElementById('printInvoice')
    if (printContents) {
        const printWindow = window.open('', '_blank')
        const header = document.head.innerHTML
        let printContent = printContents.innerHTML
        if (printWindow) {
            printWindow.document.write(`
      <html lang='en'>
        <head>
          ${header}
        </head>
        <body>
          <div>${printContent}</div>
        </body>
      </html>
    `)
        }
    }
}

export const GetTotalAccommodationCost = ({quotation}: any) => {
    let price = 0
    for (const booking_hotel of quotation.booking_hotels) {
        price += booking_hotel.rooms?.reduce((total: number, item: any) => total + parseFloat(item?.sell_price), 0)
    }
    if (quotation?.booking_custom_hotel_services?.length > 0) {
        price += quotation?.booking_custom_hotel_services?.reduce((total: number, item: any) => total + parseFloat(item?.sell_price), 0)
    }
    return (
        <>{price}</>
    )
}
export const GetLandCostForAdult = ({quotation}: any) => {
    let price = 0

    if (quotation.booking_sight_seeings && quotation.booking_sight_seeings.length > 0) {
        price += quotation.booking_sight_seeings?.reduce((total: number, item: any) => total + parseFloat(item?.per_adult_price), 0)
    }
    if (quotation.booking_transfers && quotation.booking_transfers.length > 0) {
        price += quotation.booking_transfers?.reduce((total: number, item: any) => total + parseFloat(item?.per_adult_price), 0)
    }
    if (quotation.booking_packages && quotation.booking_packages.length > 0) {
        price += quotation.booking_packages?.reduce((total: number, item: any) => total + parseFloat(item?.per_adult_price), 0)
    }
    /*if (quotation.booking_custom_services && quotation.booking_custom_services.length > 0) {
        price += quotation.booking_custom_services?.reduce((total: number, item: any) => total + parseFloat(item?.per_adult_price), 0)
    }*/
    return (
        <>{price.toFixed(2)}</>
    )
}
export const GetLandCostForChild = ({quotation}: any) => {
    let price = 0

    if (quotation.booking_sight_seeings && quotation.booking_sight_seeings.length > 0) {
        price += quotation.booking_sight_seeings?.reduce((total: number, item: any) => total + parseFloat(item?.per_child_price), 0)
    }
    if (quotation.booking_transfers && quotation.booking_transfers.length > 0) {
        price += quotation.booking_transfers?.reduce((total: number, item: any) => total + parseFloat(item?.per_child_price), 0)
    }
    if (quotation.booking_packages && quotation.booking_packages.length > 0) {
        price += quotation.booking_packages?.reduce((total: number, item: any) => total + parseFloat(item?.per_child_price), 0)
    }
    /*if (quotation.booking_custom_services && quotation.booking_custom_services.length > 0) {
        price += quotation.booking_custom_services?.reduce((total: number, item: any) => total + parseFloat(item?.per_child_price), 0)
    }*/
    return (
        <>{price.toFixed(2)}</>
    )
}

export function makeFirstCharToUpperCase(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1)
}