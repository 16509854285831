import React from 'react'
import {Col, Row} from 'react-bootstrap'

export function ManageVehicle(combination: any) {
    let response: any = {}
    if (combination && combination.length) {
        for (const combinationElement of combination) {
            if (response.hasOwnProperty(combinationElement.name)) {
                response[combinationElement.name] = response[combinationElement.name] + 1
            } else {
                response[combinationElement.name] = 1
            }
        }
        return Object.keys(response).map((key) => [key, response[key]])
    } else {
        return []
    }
}

export function PickupFrom({transfer}: any) {
    if (transfer?.pickup_airport) {
        return <strong>{transfer?.pickup_airport?.name}</strong>
    } else if (transfer?.pickup_hotel) {
        return <><strong>{transfer?.pickup_hotel?.name}</strong><br/>{transfer?.pickup_hotel?.address}</>
    } else {
        return <>{transfer?.pickup_other}
            {transfer?.pickup_other === 'Pier' && <strong>({transfer.pickup_pier.name})</strong>}</>
    }
}
export function PickupFromWithoutAddress({transfer}: any) {
    if (transfer?.pickup_airport) {
        return <strong>{transfer?.pickup_airport?.name}</strong>
    } else if (transfer?.pickup_hotel) {
        return <><strong>{transfer?.pickup_hotel?.name}, {transfer?.pickup_hotel?.city?.name}</strong></>
    } else {
        return <>{transfer?.pickup_other}
            {transfer?.pickup_other === 'Pier' && <strong>({transfer.pickup_pier.name})</strong>}</>
    }
}
export function pickupFromForProcessBooking(transfer: any) {
    if (transfer?.pickup_airport) {
        return `*${transfer?.pickup_airport?.name}*`;
    } else if (transfer?.pickup_hotel) {
        return `*${transfer?.pickup_hotel?.name}*${transfer?.pickup_hotel?.address ? `\n${transfer?.pickup_hotel?.address}` : '' }`
    } else {
        return transfer?.pickup_other === 'Pier' ? `*${transfer.pickup_pier.name}*` : ''
    }
}

export function DropTo({transfer}: any) {
    if (transfer?.drop_off_airport) {
        return <strong>{transfer?.drop_off_airport?.name}</strong>
    } else if (transfer?.drop_off_hotel) {
        return <><strong>{transfer?.drop_off_hotel?.name}</strong><br/>{transfer?.drop_off_hotel?.address}</>
    } else if(transfer?.drop_off_other) {
        return <>
            <strong>{transfer?.drop_off_other}</strong>
            {transfer?.drop_off_other === 'Pier' && <span>({transfer?.drop_off_pier?.name})</span>}
        </>
    }
    return <></>
}
export function DropToWithoutAddress({transfer}: any) {
    if (transfer?.drop_off_airport) {
        return <strong>{transfer?.drop_off_airport?.name}</strong>
    } else if (transfer?.drop_off_hotel) {
        return <><strong>{transfer?.drop_off_hotel?.name}, {transfer?.drop_off_hotel?.city?.name}</strong></>
    } else if(transfer?.drop_off_other) {
        return <>
            <strong>{transfer?.drop_off_other}</strong>
            {transfer?.drop_off_other === 'Pier' && <span>({transfer?.drop_off_pier?.name})</span>}
        </>
    }
    return <></>
}
export function dropToForProcessBooking(transfer: any) {
    if (transfer?.drop_off_airport) {
        return `*${transfer?.drop_off_airport?.name}*`
    } else if (transfer?.drop_off_hotel) {
        return `*${transfer?.drop_off_hotel?.name}*\n${transfer?.drop_off_hotel?.address}`
    } else if(transfer?.drop_off_other) {
        return transfer?.drop_off_other === 'Pier' ? `*${transfer?.drop_off_pier?.name}*` : ''
    }
}

export function FindTransferSemiPrivateTotalBaggageAllowed({searchTransfer}: any) {
    let total = +searchTransfer?.adult
    const child = searchTransfer.child.filter((child: {
        age: number;
    }) => child.age > 2).length
    return total + child
}

export function FindTotalBaggageAllowed({combinations}: any) {
    if (combinations && combinations?.length > 0) {
        return combinations.reduce((total: number, item: any) => total + item?.capacity, 0)
    }
    return 0
}

export function TermsAndCondition() {
    return <>
        <Row className='mt-4'>
            <Col md={12}>
                <table className='w-100'>
                    <thead>
                    <tr className='lightPinkBackground text-white'>
                        <th className='text-center'>Emergency Contact No.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <ul>
                                <li>+66 84 236 0681 (Hindi, English Speaking)</li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
        <Row className='px-2 mt-4'>
            <Col>
                <strong>Remarks:</strong>
                <br/>
                <strong>IMPORTANT NOTE:</strong> <span className='text-danger'>**</span>The tour rates and availability
                are
                subject to change without prior notice based on COVID regulations and guidelines imposed by the Thai
                Government. Rates available or Service Booked are indicative for reference and subject to change.
                <br/>
                Travel Rules - Kindly Check Visa Requirement and International Travel Requirement at your end.
            </Col>
        </Row>
        <Row className='px-2 mt-4'>
            <Col>
                <strong>Terms & Conditions</strong>
                <ul>
                    <li>Upon arrival at the airport, our local representative will be waiting for you, holding a
                        signboard at Arrival Hall/Exit Gate with your name on it. You will then be escorted through the
                        airport to your private vehicle, where your driver will be waiting to transfer you directly to
                        your accommodation. We have included the services of a local representative at the airport, as
                        the driver must wait in a designated car parking area and may only proceed to the airport once
                        your arrival has been confirmed by the representative.
                    </li>
                    <li><span className='text-danger'>IMPORTANT</span> All infants and children must ride in an age and
                        size appropriate child safety seat or booster seat. Customers are responsible for providing
                        their own safety seats.
                    </li>
                    <li>Tips & Porterage are not included; these are at your discretion. Unless otherwise stated,</li>
                    <li>Maximum Waiting time is 1 hour after the actual Arrival time. After this time the vehicle will
                        be released.
                    </li>
                    <li>There is no Stop over enroute for shopping, lunch, etc. during Airport / Hotel Transfers.</li>
                    <li>Vehicle luggage capacity is 1 Bag Per Adult/Child Passenger only. No Baggage is allowed with
                        Infants, If you bring extra luggage which cannot fit in the vehicle, you will need to cover any
                        additional costs incurred in transferring the extra luggage. Hand luggage should be small enough
                        to be carried on your lap. LCD TV is allowed for only for 1 Unit (less than 42 inches).
                    </li>
                    <li>If you are unable to locate our local representative for any reason, it is your responsibility
                        to call the number shown on your voucher information sheet for assistance, before making
                        alternative arrangements.
                    </li>
                    <li>If Arrival Flight Prepone/Delayed or Cancel, Kindly update immediately to our Operations Team or
                        Thailand Duty Manager to arrange Guest Pickup accordingly, if not updated we are not responsible
                        for any missed Transfers and it will be considered as no show, no refund will be entertained,
                        guest must arrange there transfer at their own. <span
                            className='text-danger'>IMPORTANT</span> If your pickup/drop off details change, you must
                        amend your booking immediately.
                    </li>
                    <li>Any Guests not available/found at the meeting point, at the time of vehicle arrival would be
                        considered NO SHOW and no refund or reinstating of their tour on another day or time would be
                        considered. In Case of No-Show Guest must arrange their own Arrangement for transfers.
                    </li>
                </ul>
                <strong>Special Note:</strong>
                <ul>
                    <li>There is no Transport Service available between Island Pier - Hotel, Koh Phi Phi Island | Koh
                        Pha Ngan |
                        Ko Tao | & more… *For Details please contact our Operation Team
                    </li>
                    <li>Guests staying at Railay Beach : Pick up/Drop at the East Side of Long Bridge : 0900 hrs.</li>
                    <li>Guest staying at Centara Grand Beach Resort: Pick up/Drop from Nopparat Thara Beach Pier : 0815
                        - 08:30
                        hrs.
                    </li>
                    <li>Guest staying at Coconut Island: Pick up/Drop from Laem Hin Pier.</li>
                </ul>
                <strong>Phuket Zone wise Areas:</strong>
                <ul>
                    <li>Zone 1: Patong Beach, Kata Beach, Karon Beach, Phuket Town</li>
                    <li>Zone 2: Kamala Beach Area</li>
                    <li>Zone 3: NaiHarn, Rawai, Panwa Beach, Surin, Bang Tao, Laguna</li>
                    <li>Zone 4: Naithon, Mai Khao</li>
                </ul>
                <strong>Krabi Zone wise Areas:</strong>
                <ul>
                    <li>Zone 1: Ao Nang</li>
                    <li>Zone 2: Kwang, Klong Muang, Tubkaek</li>
                </ul>
                <strong>Bangkok Zone wise Areas:</strong>
                <ul>
                    <li>Zone 1: Pratunam, Makkasan, Silom, Sathorn, Siam, Sukhumvit Soi 3-28</li>
                </ul>
                <strong>Pattaya Zone wise Areas:</strong>
                <ul>
                    <li>Zone 1: Pattaya City Centre Area, North Pattaya, South Pattaya</li>
                    <li>Zone 2: Jomtien</li>
                </ul>
            </Col>
        </Row>
    </>
}