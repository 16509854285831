import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {UpdateProfile} from '../modules/profile/UpdateProfile'
import {UpdatePassword} from '../modules/profile/UpdatePassword'
import {CheckAccess} from '../services/Functional'
import {PageTitle} from '../../_metronic/layout/core'
import {Calender} from '../pages/Calendar'
import CheckOut from '../modules/checkout/CheckOutPage'

const PrivateRoutes = () => {
    // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

    const Agents = lazy(() => import('../modules/agents/AgentsPage'))
    const Hotels = lazy(() => import('../modules/hotels/HotelsPage'))
    const SightSeeings = lazy(() => import('../modules/sight_seeings/SightSeeingsPage'))
    const Transfers = lazy(() => import('../modules/transfers/TransfersPage'))
    const Packages = lazy(() => import('../modules/packages/PackagesPage'))

    const Users = lazy(() => import('../modules/users/UsersPage'))
    const Groups = lazy(() => import('../modules/groups/GroupsPage'))
    const Configurations = lazy(() => import('../modules/configurations/Configurations'))

    const Banners = lazy(() => import('../modules/banners/BannersPage'))
    const DocumentTypes = lazy(() => import('../modules/document_types/DocumentTypesPage'))
    const HotelAmenities = lazy(() => import('../modules/hotel_amenities/HotelAmenitiesPage'))
    const RoomAmenities = lazy(() => import('../modules/room_amenities/RoomAmenitiesPage'))
    const Piers = lazy(() => import('../modules/piers/PiersPage'))
    const Airports = lazy(() => import('../modules/airports/AirportsPage'))
    const MasterHotels = lazy(() => import('../modules/master_hotels/MasterHotelsPage'))
    const LeaveTypes = lazy(() => import('../modules/leave_types/LeaveTypesPage'))
    const Holidays = lazy(() => import('../modules/holidays/HolidaysPage'))
    const Suppliers = lazy(() => import('../modules/suppliers/SuppliersPage'))
    const SupplierTypes = lazy(() => import('../modules/supplier_types/SupplierTypesPage'))
    const Countries = lazy(() => import('../modules/countries/CountriesPage'))
    const Cities = lazy(() => import('../modules/cities/CitiesPage'))
    const Leaves = lazy(() => import('../modules/leaves/LeavesPage'))
    const Attendances = lazy(() => import('../modules/attendances/AttendancesPage'))
    const Bookings = lazy(() => import('../modules/bookings/BookingsPage'))
    const Quotations = lazy(() => import('../modules/quotations/QuotationsPage'))
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper/>}/>
                <Route path='builder' element={<BuilderPageWrapper/>}/>
                <Route path='menu-test' element={<MenuTestPage/>}/>
                <Route path='profile/update' element={<UpdateProfile/>}/>
                <Route path='profile/change-password' element={<UpdatePassword/>}/>
                <Route
                    path='calendar'
                    element={
                        CheckAccess('calendar.index') ? (
                            <>
                                <PageTitle>Calender</PageTitle>
                                <Calender/>
                            </>
                        ) : (
                            <Navigate to='/error'/>
                        )
                    }
                />
                {/* Lazy Modules */}

                <Route
                    path='users/*'
                    element={
                        <SuspensedView>
                            <Users/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='groups/*'
                    element={
                        <SuspensedView>
                            <Groups/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='configurations'
                    element={
                        <SuspensedView>
                            <Configurations/>
                        </SuspensedView>
                    }
                />

                <Route
                    path='bookings/*'
                    element={
                        <SuspensedView>
                            <Bookings/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='quotations/*'
                    element={
                        <SuspensedView>
                            <Quotations/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='checkout/*'
                    element={
                        <SuspensedView>
                            <CheckOut/>
                        </SuspensedView>
                    }
                />

                <Route
                    path='agents/*'
                    element={
                        <SuspensedView>
                            <Agents/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='hotels/*'
                    element={
                        <SuspensedView>
                            <Hotels/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='sight_seeings/*'
                    element={
                        <SuspensedView>
                            <SightSeeings/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='transfers/*'
                    element={
                        <SuspensedView>
                            <Transfers/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='packages/*'
                    element={
                        <SuspensedView>
                            <Packages/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='leaves/*'
                    element={
                        <SuspensedView>
                            <Leaves/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='attendances/*'
                    element={
                        <SuspensedView>
                            <Attendances/>
                        </SuspensedView>
                    }
                />

                {/*Masters*/}
                <Route path='masters'>
                    <Route
                        path='banners/*'
                        element={
                            <SuspensedView>
                                <Banners/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='document_types/*'
                        element={
                            <SuspensedView>
                                <DocumentTypes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='hotel_amenities/*'
                        element={
                            <SuspensedView>
                                <HotelAmenities/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='room_amenities/*'
                        element={
                            <SuspensedView>
                                <RoomAmenities/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='piers/*'
                        element={
                            <SuspensedView>
                                <Piers/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='airports/*'
                        element={
                            <SuspensedView>
                                <Airports/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='master_hotels/*'
                        element={
                            <SuspensedView>
                                <MasterHotels/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='suppliers/*'
                        element={
                            <SuspensedView>
                                <Suppliers/>
                            </SuspensedView>
                        }
                    />

                    <Route
                        path='supplier_types/*'
                        element={
                            <SuspensedView>
                                <SupplierTypes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='leave_types/*'
                        element={
                            <SuspensedView>
                                <LeaveTypes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='holidays/*'
                        element={
                            <SuspensedView>
                                <Holidays/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='countries/*'
                        element={
                            <SuspensedView>
                                <Countries/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='cities/*'
                        element={
                            <SuspensedView>
                                <Cities/>
                            </SuspensedView>
                        }
                    />
                </Route>
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

export const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
