import {createSlice} from '@reduxjs/toolkit'

const initialHolidays = {
  holiday: undefined,
  holidaysDropdown: null
}

export const HolidaySlices = createSlice({
  name: 'holidays',
  initialState: initialHolidays,
  reducers: {
    holiday: (state, action) => {
      state.holiday = action.payload.holiday
    },
    holidayList: (state, action) => {
      state.holidaysDropdown = action.payload.holidays;
    }
  },
})