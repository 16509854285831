import {FC} from 'react'

export const ChildData: FC<{children: [{age: number}]}> = ({children}) => {
  const childResponse = children.filter((child: {age: number}) => child.age > 2)
  if (childResponse && childResponse.length) {
    const age = childResponse.map((child: {age: number}) => child.age)
    return <>{
      `${childResponse.length}(${age.join(',')}) Yrs`
    }</>
  }
  return <>0</>
}