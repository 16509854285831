import React, {FC} from 'react'
import {useDispatch} from 'react-redux'
import {Col, Modal, ModalBody, ModalHeader, ModalTitle, Row} from 'react-bootstrap'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {updateCustomServiceRemark} from '../../../../setup/redux/bookings/actions'
import {Input} from "../../../../_metronic/partials/controls";

interface assignSupplier {
    show: boolean,
    hide: any,
    customService: any,
    refetch: any
}

export const ManageCustomServiceRemark: FC<assignSupplier> = ({show, hide, customService, refetch}) => {
    const dispatch = useDispatch()
    const initValue = {
        custom_service_id: customService.id,
        remark: customService.special_request,
    }
    const assignSupplierSchema = Yup.object().shape({
        remark: Yup.string().required('Remark is required'),
    })

    return (
        <Modal show={show} centered={true} onHide={hide}>
            <ModalHeader closeButton>
                <ModalTitle>
                    Add Remark
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={initValue}
                    validationSchema={assignSupplierSchema}
                    onSubmit={async (values) => {
                        await dispatch(updateCustomServiceRemark(values))
                        refetch()
                        hide()
                    }}
                >
                    {({values, handleChange, handleBlur, handleSubmit}) => (
                        <React.Fragment>
                            <Form className='form'>
                                <Row className={'px-2'}>
                                    <Col md={12}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>
                                                Add Remark <span className='text-danger'>*</span>
                                            </label>
                                            <Field name='remark'  component={Input}/>
                                            <ErrorMessage
                                                name={`remark`}
                                                component='div' className='text-danger mt-2'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={'float-end'}>
                                    <div className='col-md-12 '>
                                        <div className='form-group'>
                                            <button
                                                type='button'
                                                name='submit'
                                                className='btn btn-primary me-2'
                                                onClick={() => handleSubmit()}
                                            >
                                                Change Now
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}