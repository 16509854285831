import {combineReducers} from 'redux'
import storage from 'redux-persist/lib/storage'
import {AuthSlices} from './auth/slices'

import {persistReducer} from 'redux-persist'
import {UserSlices} from './users/slices'
import {CommonSlices} from './common/slices'
import {GroupSlices} from './groups/slices'
import {DocumentTypeSlices} from './document_types/slices'
import {ConfigurationSlices} from './configurations/slices'
import {HotelAmenitySlices} from './hotel_amenities/slices'
import {RoomAmenitySlices} from './room_amenities/slices'
import {BannerSlices} from './banners/slices'
import {CountrySlices} from './countries/slices'
import {CitySlices} from './cities/slices'
import {PierSlices} from './piers/slices'
import {AirportSlices} from './airports/slices'
import {MasterHotelSlices} from './master_hotels/slices'
import {SupplierSlices} from './suppliers/slices'
import {SupplierTypeSlices} from './supplier_types/slices'
import {AgentSlices} from './agents/slices'
import {HotelSlices} from './hotels/slices'
import {SightSeeingSlices} from './sight_seeings/slices'
import {TransferSlices} from './transfers/slices'
import {PackageSlices} from './packages/slices'
import {LeaveSlices} from './leaves/slices'
import {BookingSlices} from './bookings/slices'
import {QuotationSlices} from './quotations/slices'
import {LeaveTypeSlices} from './leave_types/slices'
import {AttendanceSlices} from './attendances/slices'
import {HolidaySlices} from "./holidays/slices";

const authPersistConfig = {
  key: 'auth',
  storage: storage,
}

const systemConfig = {
  key: 'config',
  storage: storage,
}

const bookingConfig={
  key:'bookings',
  storage:storage
}

export const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthSlices.reducer),
  configuration: persistReducer(systemConfig, ConfigurationSlices.reducer),
  common: CommonSlices.reducer,
  users: UserSlices.reducer,
  groups: GroupSlices.reducer,
  document_types: DocumentTypeSlices.reducer,
  banners: BannerSlices.reducer,
  hotel_amenities: HotelAmenitySlices.reducer,
  room_amenities: RoomAmenitySlices.reducer,
  countries: CountrySlices.reducer,
  cities: CitySlices.reducer,
  piers: PierSlices.reducer,
  master_hotels: MasterHotelSlices.reducer,
  leave_types: LeaveTypeSlices.reducer,
  holidays: HolidaySlices.reducer,
  airports: AirportSlices.reducer,
  suppliers: SupplierSlices.reducer,
  supplier_types: SupplierTypeSlices.reducer,
  agents: AgentSlices.reducer,
  hotels: HotelSlices.reducer,
  sight_seeings: SightSeeingSlices.reducer,
  transfers: TransferSlices.reducer,
  packages: PackageSlices.reducer,
  leaves: LeaveSlices.reducer,
  attendances: AttendanceSlices.reducer,
  bookings:persistReducer(bookingConfig,BookingSlices.reducer),
  quotations: QuotationSlices.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

/*export function* rootSaga() {
  yield all([auth.saga()])
}*/