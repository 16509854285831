import * as requestFromServer from '../common/cruds'
import {SupplierSlices} from './slices'
import {handleNotificationWithToastify} from '../../../app/services/Functional'
import {callTypes, catchError, endCall, startCall} from '../common/slices'

const actions = SupplierSlices.actions
const API_URL = `${process.env.REACT_APP_API_BASE_URL}suppliers/`

export const getSupplierById = (id: number, type: string) => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .getRequest(`${API_URL}${id}/${type}`)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      dispatch(actions.supplier(response.data.data))
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find supplier'
      dispatch(catchError({error, callType: callTypes.action}))
    })
}

export const createSupplier = (payload: any) => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .postRequest(`${API_URL}`, payload)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      handleNotificationWithToastify(response.data)
      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create supplier'
      dispatch(catchError({error, callType: callTypes.action}))
      return error
    })
}

export const updateSupplier = (id: number, supplier: any) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .patchRequest(`${API_URL}${id}`, supplier)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      handleNotificationWithToastify(response.data)
      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t update supplier'
      dispatch(catchError({error, callType: callTypes.action}))
      return error
    })
}

export const deleteSupplier = (id: number) => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .deleteRequest(`${API_URL}${id}`)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      handleNotificationWithToastify(response.data)
    })
    .catch(error => {
      error.clientMessage = 'Can\'t delete supplier'
      dispatch(catchError({error, callType: callTypes.action}))
    })
}

export const getCurrencyDropdown = () => (dispatch: any) => {
  return requestFromServer
    .getRequest(`${process.env.REACT_APP_API_BASE_URL}currencies/dropdown`)
    .then(response => {
      dispatch(actions.currencyDropdown(response.data.data))
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find country'
      dispatch(catchError({error, callType: callTypes.action}))
    })
}

export const getSuppliers = (type: string, serviceId: number) => (dispatch:any) => {
    dispatch(startCall({ callType: callTypes.action }));
    return requestFromServer
        .getRequest(`${API_URL}get-suppliers/${type}/${serviceId}`)
        .then(response => {
            dispatch(endCall({ callType: callTypes.action }));
            dispatch(actions.supplierLists({ suppliers: response.data.data.suppliers }));
        })
        .catch(error => {
            error.clientMessage = "Can't get supplier type list";
            dispatch(catchError({ error, callType: callTypes.action }));
        });
};