import {useQuery} from "react-query";
import {getRequest, postRequest} from "../../../setup/redux/common/cruds";
import {AxiosResponse} from "axios";
import {PackagesQueryResponse} from "../../modules/packages/packages-list/core/_models";
import {Col, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {KTCard, KTCardBody} from "../../../_metronic/helpers";
import {
    GetBookingStatusLabel,
    GetPNRNumber,
    LongTimeFormat,
    makeFirstCharToUpperCase
} from "../../modules/bookings/helper";
import moment from "moment";
import {
    DropToWithoutAddress,
    ManageVehicle,
    PickupFromWithoutAddress
} from "../../modules/bookings/pages/transferPage/helpers/TransferHelper";
import {manageVehicle} from "../../modules/bookings/pages/sightSeenPage/booking-voucher/Private";
import Pagination from "@mui/material/Pagination";
import {ManageSightSeenSupplier} from "./partial/ManageSightSeenSupplier";
import {ManageTransferSupplier} from "./partial/ManageTransferSupplier";
import {ManageCustomServiceSupplier} from "./partial/ManageCustomServiceSupplier";
import {ManageTransferRemark} from "./partial/ManageTransferRemark";
import {ManageSightSeenRemark} from "./partial/ManageSightSeenRemark";
import {ManageCustomServiceRemark} from "./partial/ManageCustomServiceRemark";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import {handleNotificationWithToastify} from "../../services/Functional";

export const ManageBookingServices = (props: { country: string, countryCode: string }) => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [currentDate, setCurrentDate] = useState<string>(moment().format('YYYY-MM-DD'))
    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `DASHBOARD-MANAGE-SERVICES-${props.countryCode}`,
        () => {
            return getRequest(`${process.env.REACT_APP_API_BASE_URL}dashboard/services/${currentDate}?page=${currentPage}&items_per_page=10&country=${props.countryCode}`)
                .then((d: AxiosResponse<PackagesQueryResponse>) => d.data)
        },
        {
            cacheTime: 5000,
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            refetchIntervalInBackground: false,
            refetchInterval: 60000,
        },
    )
    const handlePaginationChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page)
    }
    useEffect(() => {
        refetch()
    }, [currentPage, currentDate]);

    const removeTransferSupplier = (transferId: number, supplierId: number) => {
        postRequest(
            `${process.env.REACT_APP_API_BASE_URL}bookings/remove-transfer-supplier`,
            {transfer_id: transferId, supplier_id: supplierId}
        ).then(response => {
            refetch()
        }).catch(e => {
            console.log(e)
        })
    }

    const [showTransferSupplierModal, setShowTransferSupplierModal] = useState(false);
    const [showSightSeeingSupplierModal, setShowSightSeeingSupplierModal] = useState(false);
    const [showCustomServiceSupplierModal, setShowCustomServiceSupplierModal] = useState(false);
    const [showTransferDateChangeModal, setShowTransferDateChangeModal] = useState(false);
    const [showSightSeeingDateChangeModal, setShowSightSeeingDateChangeModal] = useState(false);
    const [showCustomServiceDateChangeModal, setShowCustomServiceDateChangeModal] = useState(false);
    const [activeService, setActiveService] = useState();
    return (
        <>
            {/*{isFetching && <Loader />}*/}
            <Row className="justify-content-end">
                <Col md={3} className="form-group">
                    <input className="form-control" type="date" defaultValue={currentDate}
                           onChange={(e) => setCurrentDate(e.target.value)}/>
                </Col>
            </Row>
            <KTCard className='gutter-b'>
                <KTCardBody className="p-0">
                    <Row>
                        {response?.data?.services?.map((booking: any, index: number) => (
                            <>
                                {booking?.booking_transfers.map((transferItem: any, transferIndex: number) => (
                                    <Col md={12} key={`service_${index}_${transferIndex}_${transferItem.id}`}>
                                        <Table bordered responsive key={`transfer_${transferIndex}`}
                                               className='vertical-align-middle table-striped font-weight-bold custom-table-border custom-table-padding'>
                                            <thead>
                                            <tr className="bg-light">
                                                <th className="text-capitalize d-flex gap-2">
                                                    <span className="text-danger">Transfer</span>
                                                    <span>{transferItem.transfer.transfer_type}</span>
                                                    <span>({transferItem.transfer_type})</span>
                                                    <span><GetBookingStatusLabel
                                                        status={transferItem.booking_status}/></span>
                                                </th>
                                                <th>{moment(transferItem.transfer_date).format('DD/MM/YYYY')}</th>
                                                <th>
                                                    <div
                                                        className="d-flex justify-content-between vertical-align-middle">
                                                        <Link target="_blank"
                                                              to={`/bookings/${booking.identifier}/show`}>{GetPNRNumber({booking: booking})}</Link>
                                                        <span className="text-primary cursor-pointer"
                                                              onClick={async () => {
                                                                  if (transferItem.transfer_suppliers.length > 0) {
                                                                      const response = await postRequest(`${process.env.REACT_APP_API_BASE_URL}bookings/process-transfer`, {transfer_id: transferItem.id})
                                                                      handleNotificationWithToastify(response.data)
                                                                      await navigator.clipboard.writeText(response.data.data)
                                                                  } else {
                                                                      handleNotificationWithToastify({
                                                                          message: 'Please select supplier first',
                                                                          type: 'warning'
                                                                      })
                                                                  }
                                                              }}>Process</span>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td
                                                    className='text-capitalize w-50'>{`${booking.lead_passenger.prefix}. ${booking.lead_passenger.first_name} ${booking.lead_passenger.last_name}`}</td>
                                                <td>{transferItem.adults.length} Adult</td>
                                                <td>{transferItem.children.filter((child: {
                                                    age: number;
                                                }) => child.age > 2).length > 0
                                                    ? <span>{transferItem.children.filter((child: {
                                                        age: number;
                                                    }) => child.age > 2).length} Child</span> : ''}
                                                    {transferItem.children.filter((child: {
                                                        age: number;
                                                    }) => child.age <= 2).length > 0
                                                        ? <span>{' '}{transferItem.children.filter((child: {
                                                            age: number;
                                                        }) => child.age <= 2).length} Infant</span> : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    Pickup: <PickupFromWithoutAddress transfer={transferItem}/><br/>
                                                    Drop: <DropToWithoutAddress transfer={transferItem}/>
                                                </td>
                                                <td>
                                                    {(() => {
                                                        if (transferItem?.transfer_type?.toLowerCase() === 'private' || transferItem?.transfer_type?.toLowerCase() === 'semi_private') {
                                                            const responseData = manageVehicle(transferItem.combination)
                                                            return responseData.map((value: any) => (
                                                                <span
                                                                    className="text-capitalize">{value[1]} * {value[0]}</span>
                                                            ))
                                                        }
                                                    })()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>ET{transferItem.transfer.transfer_type.charAt(0)}: <LongTimeFormat
                                                    time={transferItem.estimated_time}/></td>
                                                <td>
                                                    {transferItem.transfer.transfer_type !== 'Local' &&
                                                        <span>Flight No. {transferItem.flight_no}</span>}
                                                </td>
                                                <td>
                                                    Pickup: <LongTimeFormat time={transferItem?.pickup_time}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <span className="text-primary cursor-pointer"
                                                          onClick={() => {
                                                              setActiveService(transferItem)
                                                              setShowTransferDateChangeModal(true)
                                                          }}>Remarks:</span> {transferItem.special_request}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="d-flex justify-content-between flex-start">
                                                        <span>
                                                            <span className="text-primary cursor-pointer"
                                                                  onClick={() => {
                                                                      setActiveService(transferItem)
                                                                      setShowTransferSupplierModal(true)
                                                                  }}>Supplier:</span>
                                                            {transferItem.transfer_suppliers.map((transferSupplier: any, index: number) => (
                                                                <p className="mb-1"
                                                                   key={`transfer_supplier_${index}_${transferSupplier.id}`}>{transferSupplier.contact_person}, {transferSupplier.company_name}({transferSupplier.phone})
                                                                    <span
                                                                        className="fa fa-remove text-danger fw-bold fs-3 cursor-pointer ms-2"
                                                                        onClick={() =>
                                                                            Swal.fire({
                                                                                icon: 'warning',
                                                                                title: 'Are you sure?',
                                                                                text: 'You won\'t be able to revert this!',
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'Yes',
                                                                                cancelButtonText: 'No',
                                                                                reverseButtons: true,
                                                                                allowOutsideClick: false,
                                                                            }).then(async (result) => {
                                                                                if (result.isConfirmed) {
                                                                                    postRequest(
                                                                                        `${process.env.REACT_APP_API_BASE_URL}bookings/remove-transfer-supplier`,
                                                                                        {
                                                                                            transfer_id: transferItem.id,
                                                                                            supplier_id: transferSupplier.id
                                                                                        }
                                                                                    ).then(response => {
                                                                                        refetch()
                                                                                    }).catch(e => {
                                                                                        console.log(e)
                                                                                    })
                                                                                }
                                                                            })} title="Remove"/></p>
                                                            ))}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    Confirmation No: {transferItem.confirmation_no}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                ))}
                                {booking?.booking_sight_seeings.map((sightseeingItem: any, sightseeingIndex: number) => (
                                    <Col md={12} key={`sightseeing_${index}_${sightseeingIndex}`}>
                                        <Table bordered responsive key={`sightseeing_data_${sightseeingIndex}`}
                                               className='vertical-align-middle table-striped font-weight-bold custom-table-border custom-table-padding'>
                                            <thead>
                                            <tr className="bg-light">
                                                <th className="text-capitalize d-flex gap-2">
                                                    <span className="text-danger">Tour</span>
                                                    <span>{sightseeingItem.sight_seeing.name}</span>
                                                    <span>({sightseeingItem.type})</span>
                                                    <span><GetBookingStatusLabel
                                                        status={sightseeingItem.booking_status}/></span>
                                                </th>
                                                <th>{moment(sightseeingItem.pickup_date).format('DD/MM/YYYY')}</th>
                                                <th>
                                                    <div
                                                        className="d-flex justify-content-between vertical-align-middle">
                                                        <Link target="_blank"
                                                              to={`/bookings/${booking.identifier}/show`}>{GetPNRNumber({booking: booking})}</Link>
                                                        <span className="text-primary cursor-pointer"
                                                              onClick={async () => {
                                                                  if (sightseeingItem.sight_seeing_suppliers.length > 0) {
                                                                      const response = await postRequest(`${process.env.REACT_APP_API_BASE_URL}bookings/process-sightseeing`, {sightseeing_id: sightseeingItem.id})
                                                                      handleNotificationWithToastify(response.data)
                                                                      await navigator.clipboard.writeText(response.data.data)
                                                                  } else {
                                                                      handleNotificationWithToastify({
                                                                          message: 'Please select supplier first',
                                                                          type: 'warning'
                                                                      })
                                                                  }
                                                              }}>Process</span>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td
                                                    className='text-capitalize w-50'>{`${booking.lead_passenger.prefix}. ${booking.lead_passenger.first_name} ${booking.lead_passenger.last_name}`}</td>
                                                <td>{sightseeingItem.adults.length} Adult</td>
                                                <td>{sightseeingItem.children.filter((child: {
                                                    age: number;
                                                }) => child.age > 2).length > 0
                                                    ? <span>{sightseeingItem.children.filter((child: {
                                                        age: number;
                                                    }) => child.age > 2).length} Child</span> : ''}
                                                    {sightseeingItem.children.filter((child: {
                                                        age: number;
                                                    }) => child.age <= 2).length > 0
                                                        ? <span>{' '}{sightseeingItem.children.filter((child: {
                                                            age: number;
                                                        }) => child.age <= 2).length} Infant</span> : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    Pickup: {sightseeingItem?.hotel?.name}
                                                </td>
                                                <td>
                                                    {(() => {
                                                        if (sightseeingItem.type !== 'SIC') {
                                                            const responseData = ManageVehicle(sightseeingItem.combination)
                                                            responseData.map((value: any, index: number) => {
                                                                if (booking?.booking_sight_seeings.type === 'semi_private') {
                                                                    return 'Type: Semi Private ' + value[1] + ' ' + makeFirstCharToUpperCase(value[0])
                                                                } else {
                                                                    return 'Type: Private ' + value[1] + ' ' + makeFirstCharToUpperCase(value[0])
                                                                }
                                                            })
                                                        } else {
                                                            return sightseeingItem?.sight_seeing?.parameters?.sic_vehicle_type
                                                        }
                                                    })()}
                                                </td>
                                            </tr>
                                            <tr>
                                                {/*<td>ET{transferItem.transfer.transfer_type.charAt(0)}: <LongTimeFormat time={transferItem.estimated_time}/></td>*/}
                                                <td>Pickup: <LongTimeFormat time={sightseeingItem?.pickup_time}/></td>
                                                <td colSpan={2}>Start: <LongTimeFormat
                                                    time={sightseeingItem?.start_time}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <span className="text-primary cursor-pointer" onClick={() => {
                                                        setActiveService(sightseeingItem)
                                                        setShowSightSeeingDateChangeModal(true)
                                                    }}>Remarks:</span> {sightseeingItem.special_request}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="d-flex justify-content-between flex-start">
                                                        <span>
                                                            <span className="text-primary cursor-pointer"
                                                                  onClick={() => {
                                                                      setActiveService(sightseeingItem)
                                                                      setShowSightSeeingSupplierModal(true)
                                                                  }}>Supplier:</span>
                                                            {sightseeingItem.sight_seeing_suppliers.map((sightseeingSupplier: any, index: number) => (
                                                                <p className="mb-1"
                                                                   key={`sightseeing_supplier_${index}_${sightseeingSupplier.id}`}>{sightseeingSupplier.contact_person}, {sightseeingSupplier.company_name}({sightseeingSupplier.phone})
                                                                    <span
                                                                        className="fa fa-remove text-danger fw-bold fs-3 cursor-pointer ms-2"
                                                                        onClick={() =>
                                                                            Swal.fire({
                                                                                icon: 'warning',
                                                                                title: 'Are you sure?',
                                                                                text: 'You won\'t be able to revert this!',
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'Yes',
                                                                                cancelButtonText: 'No',
                                                                                reverseButtons: true,
                                                                                allowOutsideClick: false,
                                                                            }).then(async (result) => {
                                                                                if (result.isConfirmed) {
                                                                                    postRequest(
                                                                                        `${process.env.REACT_APP_API_BASE_URL}bookings/remove-sightseeing-supplier`,
                                                                                        {
                                                                                            sightseeing_id: sightseeingItem.id,
                                                                                            supplier_id: sightseeingSupplier.id
                                                                                        }
                                                                                    ).then(response => {
                                                                                        refetch()
                                                                                    }).catch(e => {
                                                                                        console.log(e)
                                                                                    })
                                                                                }
                                                                            })} title="Remove"/></p>
                                                            ))}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    Confirmation No: {sightseeingItem.confirmation_no}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                ))}
                                {booking?.booking_custom_services.map((customItem: any, customIndex: number) => (
                                    <Col md={12} key={`custom_${index}`}>
                                        <Table bordered responsive key={`custom_data_${customIndex}`}
                                               className='vertical-align-middle table-striped font-weight-bold custom-table-border custom-table-padding'>
                                            <thead>
                                            <tr className="bg-light">
                                                <th className="text-capitalize d-flex gap-2">
                                                    <span className="text-danger">Custom</span>
                                                    <span>{customItem.name}</span>
                                                    <span>({customItem.transfer_type})</span>
                                                    <span><GetBookingStatusLabel
                                                        status={customItem.booking_status}/></span>
                                                </th>
                                                <th>{moment(customItem.service_date).format('DD/MM/YYYY')}</th>
                                                <th>
                                                    <div
                                                        className="d-flex justify-content-between vertical-align-middle">
                                                        <Link target="_blank"
                                                              to={`/bookings/${booking.identifier}/show`}>{GetPNRNumber({booking: booking})}</Link>
                                                        <span className="text-primary cursor-pointer"
                                                              onClick={async () => {
                                                                  if (customItem.custom_service_suppliers.length > 0) {
                                                                      const response = await postRequest(`${process.env.REACT_APP_API_BASE_URL}bookings/process-custom-service`, {custom_service_id: customItem.id})
                                                                      handleNotificationWithToastify(response.data)
                                                                      await navigator.clipboard.writeText(response.data.data)
                                                                  } else {
                                                                      handleNotificationWithToastify({
                                                                          message: 'Please select supplier first',
                                                                          type: 'warning'
                                                                      })
                                                                  }
                                                              }}>Process</span>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td
                                                    className='text-capitalize w-50'>{`${booking.lead_passenger.prefix}. ${booking.lead_passenger.first_name} ${booking.lead_passenger.last_name}`}</td>
                                                <td>{customItem.adults.length} Adult</td>
                                                <td>{customItem.children.filter((child: {
                                                    age: number;
                                                }) => child.age > 2).length} Child
                                                    <br/>
                                                    {customItem.children.filter((child: {
                                                        age: number;
                                                    }) => child.age <= 2).length} Infant
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    Pickup: {customItem.pickup_from}<br/>
                                                    Drop: {customItem.drop_to}
                                                </td>
                                                <td>{customItem.vehicle_type}</td>
                                            </tr>
                                            <tr>
                                                {/*<td>ET{transferItem.transfer.transfer_type.charAt(0)}: <LongTimeFormat time={transferItem.estimated_time}/></td>*/}
                                                <td>Pickup: <LongTimeFormat time={customItem?.pickup_time}/></td>
                                                <td colSpan={2}>Start:</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <span className="text-primary cursor-pointer" onClick={() => {
                                                        setActiveService(customItem)
                                                        setShowCustomServiceDateChangeModal(true)
                                                    }}>Remarks:</span> {customItem.special_request}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="d-flex justify-content-between flex-start">
                                                        <span>
                                                            <span className="text-primary cursor-pointer"
                                                                  onClick={() => {
                                                                      setActiveService(customItem)
                                                                      setShowCustomServiceSupplierModal(true)
                                                                  }}>Supplier:</span>
                                                            {customItem.custom_service_suppliers.map((customServiceSupplier: any, index: number) => (
                                                                <p className="mb-1"
                                                                   key={`custom_service_supplier_${index}_${customServiceSupplier.id}`}>{customServiceSupplier.contact_person}, {customServiceSupplier.company_name}({customServiceSupplier.phone})
                                                                    <span
                                                                        className="fa fa-remove text-danger fw-bold fs-3 cursor-pointer ms-2"
                                                                        onClick={() =>
                                                                            Swal.fire({
                                                                                icon: 'warning',
                                                                                title: 'Are you sure?',
                                                                                text: 'You won\'t be able to revert this!',
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'Yes',
                                                                                cancelButtonText: 'No',
                                                                                reverseButtons: true,
                                                                                allowOutsideClick: false,
                                                                            }).then(async (result) => {
                                                                                if (result.isConfirmed) {
                                                                                    postRequest(
                                                                                        `${process.env.REACT_APP_API_BASE_URL}bookings/remove-custom-service-supplier`,
                                                                                        {
                                                                                            custom_service_id: customItem.id,
                                                                                            supplier_id: customServiceSupplier.id
                                                                                        }
                                                                                    ).then(response => {
                                                                                        refetch()
                                                                                    }).catch(e => {
                                                                                        console.log(e)
                                                                                    })
                                                                                }
                                                                            })} title="Remove"/></p>
                                                            ))}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    Confirmation No: {customItem.confirmation_no}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                ))}
                            </>
                        ))}
                    </Row>
                    <Row>
                        <Col>
                            <Pagination count={Number(response?.data?.count ?? 1)}
                                        page={currentPage}
                                        siblingCount={2}
                                        onChange={handlePaginationChange}
                                        shape='rounded'
                                        color='primary'/>
                        </Col>
                    </Row>
                </KTCardBody>
            </KTCard>
            {showSightSeeingSupplierModal && <ManageSightSeenSupplier
                show={showSightSeeingSupplierModal}
                hide={() => setShowSightSeeingSupplierModal(false)}
                sightSeen={activeService}
                refetch={refetch}
            />}
            {showTransferSupplierModal && <ManageTransferSupplier
                show={showTransferSupplierModal}
                hide={() => setShowTransferSupplierModal(false)}
                transfer={activeService}
                refetch={refetch}
            />}
            {showCustomServiceSupplierModal && <ManageCustomServiceSupplier
                show={showCustomServiceSupplierModal}
                hide={() => setShowCustomServiceSupplierModal(false)}
                customService={activeService}
                refetch={refetch}
            />}
            {showTransferDateChangeModal && <ManageTransferRemark
                show={showTransferDateChangeModal}
                hide={() => setShowTransferDateChangeModal(false)}
                transfer={activeService}
                refetch={refetch}
            />}
            {showSightSeeingDateChangeModal && <ManageSightSeenRemark
                show={showSightSeeingDateChangeModal}
                hide={() => setShowSightSeeingDateChangeModal(false)}
                sightSeen={activeService}
                refetch={refetch}
            />}
            {showCustomServiceDateChangeModal && <ManageCustomServiceRemark
                show={showCustomServiceDateChangeModal}
                hide={() => setShowCustomServiceDateChangeModal(false)}
                customService={activeService}
                refetch={refetch}
            />}
        </>
    )
}