const QUERIES = {
  USERS_LIST: 'users',
  GROUPS_LIST: 'groups',
  DOCUMENT_TYPES_LIST: 'document_types',
  HOTEL_AMENITY_LIST: 'hotel_amenities',
  ROOM_AMENITY_LIST: 'room_amenities',
  COUNTRIES_LIST: 'countries',
  CITIES_LIST: 'cities',
  BANNERS_LIST: 'banners',
  BOOKINGS_LIST: 'bookings',
  QUOTATIONS_LIST: 'bookings',
  PIERS_LIST: 'piers',
  AIRPORTS_LIST: 'airports',
  MASTER_HOTEL_LIST: 'master_hotels',
  LEAVE_TYPE_LIST: 'leave_types',
  HOLIDAY_LIST: 'holidays',
  SUPPLIERS_LIST: 'suppliers',
  SUPPLIER_TYPES_LIST: 'supplier_types',
  AGENTS_LIST: 'agents',
  TRANSACTIONS_LIST: 'transactions',
  HOTELS_LIST: 'hotels',
  SIGHT_SEEINGS_LIST: 'sight_seeings',
  TRANSFERS_LIST: 'transfers',
  PACKAGES_LIST: 'packages',
  LEAVES_LIST: 'leaves',
  ATTENDANCES_LIST: 'attendances',
}

export {QUERIES}
